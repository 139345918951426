export const socialIconList = [
  {
    name: 'twitter',
    prefix: 'fab', // fab prefix for brands, fas for solid icons
    link: 'https://twitter.com/RakkatonShop',
  },
  {
    name: 'instagram',
    prefix: 'fab',
    link: 'https://www.instagram.com/rakkaton/?hl=en',
  },
  {
    name: 'facebook',
    prefix: 'fab',
    link: 'https://www.facebook.com/RakkatonShop',
  },
  {
    name: 'youtube',
    prefix: 'fab',
    link: 'https://www.youtube.com/channel/UC5Tk9XO2pZrdur9ZRyCXvcA?view_as=subscriber',
  }
  // Add further social links with the icon of choice and link here
  // check https://fontawesome.com/icons?d=gallery&s=brands for other brand icons
];
